@import url("./assets/css/bootstrap.min.css");
@import url("./assets/css/bootstrap-icons.css");
@import url("./assets/css/all.min.css");
@import url("./assets/css/fontawesome.min.css");
@import url("./assets/css/swiper-bundle.min.css");
@import url("./assets/css/nice-select.css");
@import url("./assets/css/jQuery-plugin-progressbar.css");
@import url("./assets/css/barfiller.css");
@import url("./assets/sass/style.css");

$primary-color: #00cbd9;

.circular-progress-bar {
    .circular-progress-bar-trail {
        stroke: #000;
        stroke-linecap: round;
    }

    .circular-progress-bar-path {
        stroke: $primary-color;
        stroke-linecap: round;
        transition: stroke-dashoffset 0.5s ease 0s;
    }

    .circular-progress-bar-text {
        fill: $primary-color;
        font-size: 22px;
        font-weight: 700;
    }
}

.single-portfolio.masonary.masonary-top-72 {
    margin-top: -72px;
}

.single-portfolio.masonary.massonary-top-30 {
    margin-top: -30px;
}

.single-portfolio.masonary.masonary-top-113 {
    margin-top: -113px;
}

.progressbar-label {
    color: #fff;
    padding-left: 10px;
}

.out-story .circular-progress-bar {
    .circular-progress-bar-path {
        stroke: #d6d6d6;
    }

    .circular-progress-bar-text {
        fill: #18191d;
        font-size: 18px;
        font-weight: 600;
        transform: translateY(-22px);
    }
}

.wrapper {
    margin: auto;
    width: 60%;
    text-align: center;
    line-height: 2em;
}

.hover .cursor-dot {
    width: 100px;
    height: 100px;
    z-index: -1;
    background-color: lightblue;
}

.hover .cursor-border {
    z-index: -1;
}

.link {
    padding: 1em;
}

.react-hover .cursor-border {
    background-color: white;
    width: 50px;
    height: 50px;
    border-radius: 0;
    z-index: -1;
}

.react-hover .cursor-dot {
    z-index: -3;
}

.author {
    color: orange;
    font-weight: 800;
    font-size: 1.3em;
}

.author-on-hover .cursor-dot {
    width: 100px;
    height: 100px;
    background-color: gray;
    z-index: -1;
}

.author-on-hover .cursor-border {
    z-index: -1;
}

.head {
    color: bisque;
}

.head-hover .cursor-border {
    border: 5px dotted darkorange;
}

.head-hover .cursor-dot {
    background-color: purple;
    width: 20px;
    height: 20px;
}

.children {
    color: rgb(114 253 0);
}

.children-hover .cursor-dot {
    width: 600px;
    height: 600px;
    background-color: white;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sub-menu li a {
    cursor: pointer !important;
}

.portfolio-hover a img {
    color: #fff;
    background: transparent;
    border: 1px solid #fff;
    border-radius: 50px;
    height: 30px;
    width: 30px;
    line-height: 30px;
    padding: 5px;
}

.srl-thumbnails-container.css-13uajvl {
    display: none !important;
}

.scroll-top.opacity {
    color: $primary-color;
    font-weight: 800;
}
